



























import { SummaryViewModel } from '@/modules/profile/viewmodels/summary-viewmodel'
import { Observer } from 'mobx-vue'
import { Component, Inject, Vue } from 'vue-property-decorator'

@Observer
@Component({
  components: {
    'post-grid': () => import('@/modules/community/common/components/post-grid.vue'),
    observer: () => import('vue-intersection-observer'),
  },
})
export default class PostLists extends Vue {
  @Inject() vm!: SummaryViewModel
  loadMore() {
    // if (this.vm.posts?.canLoadMorePost) {
    //   this.vm.posts.loadMore()
    // }
  }

  syncFollowState(postStore) {
    const postStoreWithSameOwners =
      this.vm.posts?.posts.filter((item) => item.post.profile.id === postStore.post.profile.id) || []
    postStoreWithSameOwners.map((item) => (item.post.isFollowing = postStore.post.isFollowing))
    this.$forceUpdate()
  }
}
